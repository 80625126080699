import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import Layout from "components/Layout";
import Partenaires from "components/Partenaires";
import { slugify, removeSpaces } from "utils/index";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { StaticImage } from "gatsby-plugin-image";

/* Markdown Converter */
const showdown = require("showdown");
const converter = new showdown.Converter();

const DEFAULt_INSTRUCTION_CONTENT = {
  title: "",
  points: [],
  footers: [],
};

const CountryPages = ({ pageContext: content, location }) => {
  const {
    _companies,
    _countries,
    _others,
    _otherCountries,
    _partners,
    _staticTexts,
    _richTextBlock,
    _topMenus,
    _companyMenus,
    _lang,
    _blogs,
  } = content;

  // Get Instructions content from Contentful
  const getInstructionContent = useMemo(() => {
    const newInstructionContent = DEFAULt_INSTRUCTION_CONTENT;
    let _instructionContent = _richTextBlock.find(
      (tb) => tb.title === "Instructions"
    );

    if (_instructionContent) {
      try {
        _instructionContent = _instructionContent.richText.content;

        newInstructionContent.title = _instructionContent[0].content[0].value;
        newInstructionContent.points = [
          _instructionContent[1],
          _instructionContent[2],
          _instructionContent[3],
          _instructionContent[4],
        ].map((content) => content.content[0].value);
        newInstructionContent.footers = [
          _instructionContent[5],
          _instructionContent[6],
          _instructionContent[7],
          _instructionContent[8],
        ].map((content) => ({
          text: content.content[0].value,
          highlight: content.content[1].content[0].value,
          link: content.content[1].data.uri,
        }));
      } catch (error) {
        console.log(error);
      }
    }

    return newInstructionContent;
  }, [_richTextBlock]);

  /**
   * Get problem_alt_losali content from Contentful and mapping it
   */
  const getProlemAltContent = useMemo(() => {
    const newProlemAltContent = {
      title: "",
      paragraphs: [],
    };
    let _prolemAltContent = _richTextBlock.find(
      (tb) => tb.title === "problem_alt_losali"
    );

    if (_prolemAltContent) {
      try {
        _prolemAltContent = _prolemAltContent.richText.content;

        newProlemAltContent.title = _prolemAltContent[0].content[0].value;
        newProlemAltContent.paragraphs.push(
          _prolemAltContent[1].content[0].value
        );
        newProlemAltContent.paragraphs.push(
          _prolemAltContent[2].content[0].value
        );
      } catch (error) {
        console.log(error);
      }
    }

    return newProlemAltContent;
  }, [_richTextBlock]);

  /**
   * Get sidebar_countries content from Contentful and mapping it
   */
  const getSidebarContent = useMemo(() => {
    const newSidebartContent = {
      title: "",
      subtitle1: {
        text: "",
        link: "",
      },
      subtitle2: "",
      paragraphs: [],
      footer: "",
    };
    let _sidebarContent = _richTextBlock.find(
      (tb) => tb.title === "sidebar_countries"
    );

    if (_sidebarContent) {
      try {
        _sidebarContent = _sidebarContent.richText.content;

        newSidebartContent.title = _sidebarContent[0].content[0].value;
        newSidebartContent.subtitle1 = {
          text: _sidebarContent[1].content[1].content[0].value,
          link: _sidebarContent[1].content[1].data.uri,
        };
        newSidebartContent.subtitle2 = _sidebarContent[2].content[0].value;

        newSidebartContent.paragraphs.push(_sidebarContent[3].content[0].value);
        newSidebartContent.paragraphs.push(_sidebarContent[4].content[0].value);
        newSidebartContent.paragraphs.push(_sidebarContent[5].content[0].value);
        newSidebartContent.paragraphs.push(_sidebarContent[6].content[0].value);
        newSidebartContent.paragraphs.push(_sidebarContent[7].content[0].value);
        newSidebartContent.paragraphs.push(_sidebarContent[8].content[0].value);
        newSidebartContent.paragraphs.push(_sidebarContent[9].content[0].value);
        newSidebartContent.paragraphs.push(
          _sidebarContent[10].content[0].value
        );

        newSidebartContent.footer = _sidebarContent[11].content[0].value;
      } catch (error) {
        console.log(error);
      }
    }

    return newSidebartContent;
  }, [_richTextBlock]);

  const [sidebarContent] = useState(getSidebarContent);
  const [instructionContent] = useState(getInstructionContent);
  const [problemAlt] = useState(getProlemAltContent);

  const onBtnClick = () => {
    window.open("https://losali.com?lang=" + _lang);
  };

  return (
    <Layout
      menuContent={{
        companies: _companies,
        countries: _countries,
        others: _others,
        otherCountries: _otherCountries,
        topMenus: _topMenus,
        companyMenus: _companyMenus,
        blogs: _blogs,
      }}
      menuSection={content.menuSection}
      menuTitle={"Losali"}
      slug={slugify(
        `appeler-les-08-et-les-numeros-surtaxes-depuis-${
          content.pronoun ? content.pronoun + "-" : ""
        }${content.slug}`
      )}
      ratingValue={content.ratingValue || 4.9}
      ratingCount={content.ratingCount || 121}
      staticTexts={_staticTexts}
      lang={_lang}
    >
      <Helmet>
        <title>{content.pageTitle} | Losali Direct</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={content.metaKeywords} />
        <meta name="description" content={content.metaDescription} />
      </Helmet>
      <main className="container page-width top-margin">
        <div className="page2-card">
          <div className="page2-box1">
            <div className="page2-card-left card-primary">
              <h1 className="page2-left-text h2-title">{content.h2}</h1>
              <h4 className="page2-left-text">{content.main}</h4>
            </div>
            <div className="page2-card-left card-primary">
              <div>
                <div className="page2-left-text float-left h2-title">
                  {content.country}
                </div>
                <div className="page2-flag float-right">
                  <LazyLoadImage
                    src={`https:${content.image.fields.file.url}`}
                    alt={content.altText}
                  />
                </div>
              </div>

              <div className="page2-numbers">
                {content.number ? (
                  <>
                    <a
                      className="primary-text h2-title"
                      href={"tel:" + removeSpaces(content.number)}
                    >
                      {removeSpaces(content.number)}
                    </a>
                  </>
                ) : null}
                <div
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(content.cost),
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(content.body),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="page2-box2 hide-mobile">
            <div className="page2-card-right card-primary">
              {sidebarContent && (
                <div className="page2-right-text">
                  <h3>{sidebarContent.title}</h3>

                  <h5 className="connexion">
                    <Link to={sidebarContent.subtitle1.link}>
                      {sidebarContent.subtitle1.text}
                    </Link>
                  </h5>

                  <h3>{sidebarContent.subtitle2}</h3>

                  <div className="connexion connexion-spacing">
                    {sidebarContent.paragraphs.map((p) => (
                      <div className="h5-title" key={p}>
                        {p}
                      </div>
                    ))}
                  </div>

                  <h3>{sidebarContent.footer}</h3>

                  <div className="social-media">
                    <span className="social-media-like float-left"></span>
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.losalidirect.com%2F&display=popup&ref=plugin&src=like&kid_directed_site=0"
                      target="_blank"
                      rel="noreferrer"
                      className="social-media-text"
                    >
                      {_staticTexts.like}
                    </a>
                  </div>

                  <div className="social-media">
                    <span className="social-media-share float-left"></span>
                    <span
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://losalidirect.com`
                        );
                      }}
                      aria-hidden="true"
                      className="social-media-text"
                    >
                      {_staticTexts.share}
                    </span>
                  </div>

                  <div className="social-media">
                    <span className="social-media-tweet float-left"></span>
                    <a
                      href="https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Flosalidirect.com%2F&ref_src=twsrc%5Etfw&text=Avec%20Losali%20Direct%20appelez%20les%20num%C3%A9ros%20courts%20et%20surtax%C3%A9s%20depuis%20l%27%C3%A9tranger&tw_p=tweetbutton&url=https%3A%2F%2Fwww.losalidirect.com%2F&via=Narga"
                      target="_blank"
                      rel="noreferrer"
                      className="social-media-text"
                    >
                      {_staticTexts.tweet}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <section className="container page-width">
        <div className="instruction-card card-secondary">
          <div className="instruction-box">
            <div className="h2-title">{instructionContent.title}</div>
          </div>

          <div className="instruction-box">
            {instructionContent.points.map((point, index) => (
              <div className="number-list" key={index}>
                <span className={`number-list-${index + 1} float-left`}></span>
                <p>{point}</p>
              </div>
            ))}
          </div>

          <div className="instruction-footer">
            {instructionContent.footers.map((footer, index) => (
              <h5 key={index}>
                {footer.text}{" "}
                <span className="secondary-text">
                  <a href={footer.link}>{footer.highlight}</a>
                </span>
              </h5>
            ))}
          </div>
        </div>
      </section>

      <section className="container page-width">
        <div className="join-card card-primary">
          <span className="join-card-left">
            <div className="join-card-h2 h2-title">{problemAlt.title}</div>
            {problemAlt.paragraphs.map((p) => (
              <h3 className="join-description" key={p}>
                {p}
              </h3>
            ))}
            <div style={{ textAlign: "center" }}>
              <button className="losali-btn" onClick={onBtnClick}>
                {_staticTexts.goToLosali}
              </button>
            </div>
          </span>
          <span className="join-card-right">
            <StaticImage
              src="../assets/images/country-img.webp"
              alt="..."
              placeholder="blurred"
            />
          </span>
        </div>
      </section>

      <Partenaires partners={_partners} />
    </Layout>
  );
};

export default CountryPages;
